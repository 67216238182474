<template>
  <div class="app-container">
    <el-form ref="form" :model="form" label-width="100px" style="display: flex;">
      <div class="el-lt" style="width:85%;">
        <el-row class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item label="差异单号">
              <el-input v-model.trim="form.handleCode" clearable placeholder="请输入" />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="关联单号">
              <el-input v-model.trim="form.taskId" clearable placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="仓库" prop="outboundCode">
              <el-select v-model="form.logicWarehouseCode" clearable filterable placeholder="请选择">
                <el-option
                  v-for="item in warehouseList"
                  :key="item.logicWarehouseCode"
                  :value="item.logicWarehouseCode"
                  :label="item.logicWarehouseName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="差异类型" prop="outboundCode">
              <el-select v-model="form.abnormalType" clearable placeholder="请选择">
                <el-option v-for="item in getDictionariesOpt('VWMS_ABNORMAL_TYPE')" :key="item.val" :value="item.val" :label="item.label" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="差异时间">
              <el-date-picker
                v-model="form.date"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="~"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                :unlink-panels="true"
                :default-time="['00:00:00', '23:59:59']"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row :span="24" class="mb-3">
          <el-col :span="24" style="display:flex;height:36px;">
            <el-button type="primary" @click="handleExport">导出</el-button>
          </el-col>
        </el-row> -->
      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
          <el-button type="text" @click="showRowClick()">{{ showRow ? $t('page.hide') : $t('page.expand') }}</el-button>
        </el-col>
      </el-row>
    </el-form>

    <el-row style="width:100%;margin:20px 0;">
      <el-col style="width:100%;padding: 15px 0px; white-space: nowrap;">
        <el-tabs v-model="form.abnormalStatus" @tab-click="handleTabsClick">
          <el-tab-pane label="待处理" name="1">
            <el-table ref="multipleTable" class="mb-3" :data="tableData" :header-cell-style="{background:'#fafafa'}" max-height="500px">
              <el-table-column
                type="index"
                :label="$t('page.No')"
                align="center"
              />
              <el-table-column prop="handleCode" label="差异单号" align="center">
                <template slot-scope="scope"><router-link :to="{ append:true, path: 'details', query: {handleCode: scope.row.handleCode,row:JSON.stringify(scope.row)}}" style="color:#1890ff;">{{ scope.row.handleCode }}</router-link></template>
              </el-table-column>
              <el-table-column align="center" prop="logicWarehouseName" label="仓库" />
              <el-table-column prop="taskId" width="190" label="关联单号" align="center">
                <template slot-scope="scope">

                  <!-- <span v-if="scope.row.abnormalType == 'OUT'">{{ scope.row.taskId.split(',') }} {{ '-------' }}<router-link :to="{ append:true, path: '../../ware-manage/stock-out/ex-manage/details', query: {taskId: scope.row.taskId, abnormalType: scope.row.abnormalType, handleCode: scope.row.handleCode}}" style="color:#1890ff;">{{ scope.row.taskId }}</router-link></span> -->
                  <span v-if="scope.row.abnormalType == 'OUT'">
                    <span v-for="(item,ind) in scope.row.taskId" :key="ind">
                      <router-link :to="{ append:true, path: '../../ware-manage/stock-out/ex-manage/details', query: {taskId: item, abnormalType: scope.row.abnormalType, handleCode: scope.row.handleCode}}" style="color:#1890ff;">{{ item+' ' }}</router-link>
                    </span>
                  </span>
                  <span v-if="scope.row.abnormalType == 'IN'">
                    <span v-for="(item1,index) in scope.row.taskId" :key="index">
                      <router-link :to="{ append:true, path: '../../ware-manage/stock-in/receipt-manage/details', query: {taskId: item1, abnormalType: scope.row.abnormalType, handleCode: scope.row.handleCode}}" style="color:#1890ff;">{{ item1+' ' }}</router-link>
                    </span>
                  </span>
                  <!-- <span else>
                    <span v-for="(item1,index) in scope.row.taskId" :key="index">
                      <router-link :to="{ append:true, path: '../../ware-manage/stock-in/receipt-manage/details', query: {taskId: item1, abnormalType: scope.row.abnormalType, handleCode: scope.row.handleCode}}" style="color:#1890ff;">{{ item1+' ' }}</router-link>
                    </span>
                  </span> -->
                </template>
              </el-table-column>
              <el-table-column align="center" prop="surplusCode" label="盘盈入库单号" />
              <el-table-column align="center" prop="lossCode" label="盘亏出库单号">
                <template slot-scope="scope">
                  <span v-if="scope.row.abnormalType == 'OUT'"><router-link :to="{ append:true, path: '../../ware-manage/stock-out/ex-manage/details', query: {taskId: scope.row.lossCode, abnormalType: 'OUT', handleCode: scope.row.handleCode}}" style="color:#1890ff;">{{ scope.row.lossCode }}</router-link></span>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="createTime" label="差异时间" />
              <el-table-column align="center" prop="expectPairs" label="总预期数量" />
              <el-table-column align="center" prop="actualPairs" label="总实际数量" />
              <el-table-column align="center" prop="abnormalPairs" label="总差异数量" />
              <el-table-column prop="reason" label="差异类型" align="center">
                <template slot-scope="scope">{{ abnormalTypeLabel(scope.row.abnormalType) }}</template>
              </el-table-column>
              <el-table-column prop="reason" label="差异原因" align="center">
                <template slot-scope="scope">{{ processResultLabel(scope.row.reason) }}</template>
              </el-table-column>
              <el-table-column align="center" prop="modifyByName" label="处理人" />
              <el-table-column align="center" prop="modifyTime" label="处理时间" />
              <!-- 操作 -->
              <el-table-column :label="$t('page.operate')">
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.row.reason == '在途差异'"
                    type="text"
                    size="small"
                    @click="ChangeAbnormalOnWay(scope.row)"
                  >差异处理</el-button>
                  <el-button
                    v-if="(scope.row.abnormalStatus == 1 && scope.row.reason == '出入库数量差异' && scope.row.abnormalType == 'OUT')"
                    type="text"
                    size="small"
                    @click="ChangeAbnormal(scope.row)"
                  >差异处理</el-button>
                  <el-button
                    v-if="(scope.row.abnormalStatus == 1 && scope.row.reason == '未匹配到PlatSKU' && scope.row.abnormalType == 'IN') || (scope.row.abnormalStatus == 1 && scope.row.reason == '未匹配到PlatSKU' && scope.row.abnormalType == 'OUT') "
                    type="text"
                    size="small"
                    @click="changeAbnormalPlat(scope.row)"
                  >差异处理</el-button>
                </template>
              </el-table-column>
            </el-table>

          </el-tab-pane>
          <el-tab-pane label="已处理" name="2">
            <el-table ref="multipleTable" class="mb-3" :data="tableData" :header-cell-style="{background:'#fafafa'}" max-height="500px">
              <el-table-column
                type="index"
                :label="$t('page.No')"
                align="center"
              />
              <el-table-column prop="handleCode" label="差异单号" align="center">
                <template slot-scope="scope"><router-link :to="{ append:true, path: 'details', query: {handleCode: scope.row.handleCode,row:JSON.stringify(scope.row)}}" style="color:#1890ff;">{{ scope.row.handleCode }}</router-link></template>
              </el-table-column>
              <el-table-column align="center" prop="logicWarehouseName" label="仓库" />
              <el-table-column prop="taskId" width="190" label="关联单号" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.abnormalType == 'OUT'">
                    <span v-for="(item,ind) in scope.row.taskId" :key="ind">
                      <router-link :to="{ append:true, path: '../../ware-manage/stock-out/ex-manage/details', query: {taskId: item, abnormalType: scope.row.abnormalType, handleCode: scope.row.handleCode}}" style="color:#1890ff;">{{ item+' ' }}</router-link>
                    </span>
                  </span>
                  <span v-if="scope.row.abnormalType == 'IN'">
                    <span v-for="(item1,index) in scope.row.taskId" :key="index">
                      <router-link :to="{ append:true, path: '../../ware-manage/stock-in/receipt-manage/details', query: {taskId: item1, abnormalType: scope.row.abnormalType, handleCode: scope.row.handleCode}}" style="color:#1890ff;">{{ item1+' ' }}</router-link>
                    </span>
                  </span>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="surplusCode" label="盘盈入库单号">
                <template slot-scope="scope">
                  <router-link :to="{ append:true, path: '../../ware-manage/stock-in/receipt-manage/details', query: {abnormalType:'IN',taskId: scope.row.surplusCode, handleCode: scope.row.handleCode}}" style="color:#1890ff;">{{ scope.row.surplusCode }}</router-link>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="lossCode" label="盘亏出库单号">
                <template slot-scope="scope">
                  <span><router-link :to="{ append:true, path: '../../ware-manage/stock-out/ex-manage/details', query: {abnormalType:'OUT',taskId: scope.row.lossCode, handleCode: scope.row.handleCode}}" style="color:#1890ff;">{{ scope.row.lossCode }}</router-link></span>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="createTime" label="差异时间" />
              <el-table-column align="center" prop="expectPairs" label="总预期数量" />
              <el-table-column align="center" prop="actualPairs" label="总实际数量" />
              <el-table-column align="center" prop="abnormalPairs" label="总差异数量" />
              <el-table-column prop="reason" label="差异类型" align="center">
                <template slot-scope="scope">{{ abnormalTypeLabel(scope.row.abnormalType) }}</template>
              </el-table-column>
              <el-table-column prop="reason" label="差异原因" align="center">
                <template slot-scope="scope">{{ processResultLabel(scope.row.reason) }}</template>
              </el-table-column>
              <el-table-column align="center" prop="modifyByName" label="处理人" />
              <el-table-column align="center" prop="modifyTime" label="处理时间" />

            </el-table>

          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
    <Paging :pager="pager" @update="update" />

    <el-dialog
      :close-on-click-modal="false"
      title=""
      :visible.sync="visible"
      width="600px"
      @close="closeDialog"
    >
      <el-form ref="resultForm" :model="resultForm" label-width="8em" :rules="rules">
        <el-form-item label="差异处理方式" prop="processResult">
          <el-select v-model="resultForm.processResult" :placeholder="$t('page.selectPlaceholder')">
            <el-option v-for="item in getDictionariesOpt('VWMS_OUT_ABNORMAL')" :key="item.val" :value="item.val" :label="item.label" />
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="visible = false">{{ $t('title.cancel') }}</el-button>
        <el-button type="primary" @click="handleUpdata()">确 认</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
import Paging from '@/components/Paging'
import { pageAbnormalTask, handleHandleCode } from '@/api/inventory-result'
import { getWarehouse } from '@/api/stockin'
import { Mixin } from '@/mixin/mixin.js'
export default {
  components: {
    Paging
  },
  mixins: [Mixin],
  data() {
    return {
      warehouseList: [],
      form: {
        handleCode: '',
        abnormalStatus: '1',
        logicWarehouseCode: '',
        abnormalType: '',
        taskId: '',
        date: []
      },
      stockTotals: {},
      tableLoading: false,
      tableData: [],
      showRow: false, // 切换显示input
      pager: {
        size: 20,
        current: 1,
        total: 0
      },
      row: {},
      resultForm: {},
      visible: false,
      rules: {
        processResult: [{ required: true, message: '请选择差异结果', trigger: ['blur', 'change'] }]

      }
    }
  },
  computed: {
    queryParams() {
      const [startTime = '', endTime = ''] = this.form.date || []
      const { handleCode, abnormalStatus, abnormalType, logicWarehouseCode, taskId } = this.form
      const { size, current } = this.pager
      return Object.assign({}, { size, current, startTime, endTime, handleCode, abnormalStatus, abnormalType, logicWarehouseCode, taskId })
    }
  },
  mounted() {
    this._pageAbnormalTask()
    this._queryWarehouseList()
  },
  methods: {
    handleExport() {

    },
    queryClick() {
      this.pager.current = 1
      this._pageAbnormalTask()
    },
    resetClick() { // 点击重置清空文本框信息
      Object.assign(this.form, this.$options.data.call(this).form)
      this._pageAbnormalTask()
    },
    // 点击展开控制文本框的显示隐藏
    showRowClick() {
      this.showRow = !this.showRow
    },
    handleTabsClick() {
      this.pager.current = 1
      this._pageAbnormalTask()
    },
    async _pageAbnormalTask(flag) {
      try {
        this.tableLoading = true
        flag && flag === 1 ? this.pager.current = 1 : ''
        const { datas: { pager, records }} = await pageAbnormalTask(this.queryParams)
        this.pager = pager
        this.tableData = records
        this.tableData.map(e => {
          e.taskId = e.taskId ? e.taskId.split('/') : []
        })
      } finally {
        this.tableLoading = false
      }
    },
    async _queryWarehouseList() {
      const { datas } = await getWarehouse()
      this.warehouseList = datas
    },
    update(val) {
      this.pager = val
      this._pageAbnormalTask()
    },
    // 差异处理········
    ChangeAbnormal(row) {
      this.visible = true
      this.row = row
    },
    ChangeAbnormalOnWay(row) {
      const { taskId, handleCode, abnormalType } = row
      const HandleCodeObj = { taskId: taskId ? taskId.join('/') : '', handleCode, abnormalType }
      this._handleHandleCode(HandleCodeObj)
    },
    changeAbnormalPlat(row) {
      const { taskId, handleCode, abnormalType } = row
      const HandleCodeObj = { taskId: taskId ? taskId.join('/') : '', handleCode, abnormalType, processResult: '' }
      this._handleHandleCode(HandleCodeObj)
    },
    handleUpdata() {
      this.$refs['resultForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        const { taskId, handleCode, abnormalType } = this.row
        const HandleCodeObj = { taskId: taskId ? taskId.join('/') : '', handleCode, abnormalType, processResult: this.resultForm.processResult }
        this._handleHandleCode(HandleCodeObj)
      })
    },

    async _handleHandleCode(data) {
      try {
        const { code, msg } = await handleHandleCode(data)
        if (code === 0) {
          this.visible = false
          this.$message.success(msg)
          this._pageAbnormalTask()
        } else {
          this.visible = false
          this.$message.success(msg)
          this._pageAbnormalTask()
        }
        this.resultForm.processResult = ''
      } catch (err) {
        this.resultForm.processResult = ''
      }
    },
    closeDialog() {
      this.visible = false
      this._pageAbnormalTask()
    }
  }
}
</script>
<style lang="scss" scope>
.bg-purple-dark {
  border: 1px solid #bae7ff;
  background: #e6f7ff;
  color: #1890ff;
  size: 14px;
  padding: 6px;
  margin-bottom: 10px;
  .bg-purple-title {
    line-height: 36px;
    text-indent: 20px;
  }
}
.float-left {
  float: left;
}
</style>
